import './Navbar.css'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'

export default class Navbar extends Component {
  render() {
    return (
      <div id="navbar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/homecourt">HomeCourt</Link>
          </li>
          <li>
            <Link to="/hootsuite">Hootsuite</Link>
          </li>
          <li>
            <Link to="/api/auth/login">PDF Highlights</Link>  
          </li>
          <li>
            <Link to="/epub-maker">EPUB Maker</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li style={{float: "right"}}><a href="https://github.com/augudyne">GitHub</a></li>
        </ul>
      </div>)
  }
}
