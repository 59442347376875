import styled from "styled-components";

export const StyledLink = styled.a`
  text-decoration: none;
  color: darkseagreen;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  alignItems: center;
`;
