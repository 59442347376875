/* eslint-disable no-undef */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  console.log(error); // eslint-disable-line no-console
  throw error;
}

function parseJSON(response) {
  return response.json();
}

function getServerEndpointURL() {
// Resolve the server path
  let protocol = window.location.protocol;
  var host = window.location.host;
  if (process.env.NODE_ENV === "development") {
    host = "localhost:5001"
  }

  return `${protocol}//${host}`;
}

function getResolvedLink(relativeURL) {
  return `${getServerEndpointURL()}${relativeURL}`;
}

function fetchRequestReturningJSON(relativeURL) {
  return fetch(`${getResolvedLink(relativeURL)}`, {
      accept: "application/json"
    })
    .then(checkStatus)
    .then(parseJSON)
}

export default {
    getSummary: function(cb) {
      return fetchRequestReturningJSON("/api/summary").then(cb);
    },
    getWord: function(word, cb) {
      return fetchRequestReturningJSON('/api/words/' + word).then(cb);
    },
    getResolvedLink: function(relativeURL) {
      return getResolvedLink(relativeURL);
    }
};
