import React, { Component } from 'react'
import styled from "styled-components"
import { CardContainer } from "../components/StyledComponents";
import * as ICON_GOOGLE_SIGN_IN from './btn_google_signin_dark_normal_web.png'

const NormalHeading = styled.h2`
  color: black;
  margin: 15px 0px;
`;

export default class APILogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'PDF Highlights Reviewer',
        };
    }

    render() {
        return (
            <div className="single-container" style={{ height: "100%" }}>
                <CardContainer>
                    <div style={{ width: "600px", margin: "auto", textAlign: "center" }}>
                        <br />
                        <h1>Welcome to {this.state.title}!</h1>
                        <br />
                        This is the Web API portal for my current project. Users can sign in through Google and review their .PDF highlights.
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <a href="/api/auth/google">
                                <img src={ICON_GOOGLE_SIGN_IN} />
                            </a>
                        </div>
                    </div>
                </CardContainer>
            </div>
        )
    }

}
