import styled, {keyframes} from 'styled-components'

export const PageHeader = styled.h1`
    width: 100%;
    font-size: 3em;
    padding-top: 25px;
    text-align: center;
    background-color: rgb(255, 255, 247);
`

export const FinalContainer = styled.div`
`;

export const LeftToRightKeyframes = keyframes`
  0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  
  100% {
    transform: translateX(0px);
    opacity: 100%;
  }
`

export const RightToLeftKeyframes = keyframes`
  0% {
    transform: translateX(150px);
    opacity: 0%;
  }
  
  100% {
    transform: translateX(0px);
    opacity: 100%;
  }
`

export const PulseOrange = keyframes`
    0% { 
        opacity: 0.5;
        color: initial;
    }
    50% { 
        opacity: 1.0;
        color: darkorange;
        transform: scale(1.05);
    }
    100% { 
        opacity: 0.5;
        color: initial;
    }
`;

// TODO :: Move these to components/StyledComponents