import { PageHeader } from "../styles/global";
import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Client from "../Client";

export default class EpubMaker extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleTextChange = this.handleTextChange.bind(this);
        this.urlSubmitted = this.urlSubmitted.bind(this);
        this.renderDownloadLink = this.renderDownloadLink.bind(this);
    }

    renderTable = (highlights) => {
        if (highlights) {
            return (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Document</TableCell>
                                <TableCell align="right">Page</TableCell>
                                <TableCell align="right">Comment Author</TableCell>
                                <TableCell align="right">Comment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {highlights.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.fileTitle}
                                    </TableCell>
                                    <TableCell align="right">{row.page}</TableCell>
                                    <TableCell align="right">{row.author}</TableCell>
                                    <TableCell align="right">{row.contents}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    };

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(opf.html)','i');
        return !!pattern.test(str);
    }

    handleTextChange = (event) => {
        this.setState({
            link: event.target.value,
            inputValid: this.validURL(event.target.value),
            helperText: this.validURL(event.target.value) ? null : "Not a valid URL"
        })
    }

    urlSubmitted = (event) => {
        event.preventDefault();
        if (!this.state.link) {
            return;
        }
        const query = "?destination=" + encodeURIComponent(this.state.link);
        const destination = "/api/download-epub" + query;
        const resolvedLink = Client.getResolvedLink(destination);
        console.log(`"Set download link to ${resolvedLink}`);
        this.setState({downloadLink: resolvedLink });
    };

    renderInput = () => {
        return <form noValidate autoComplete="off" onSubmit={this.urlSubmitted}>
            <h3>Links in the format https://www.ddshu.net/html3/*/opf.html converted to ePub</h3>
        <p style={{lineHeight: "18px", textAlign: "left"}}>
          Paste a link of a ddshu book e.g. https://www.ddshu.net/html3/108285/opf.html to convert it as an ePub for use on Kobo e-Reader.<br />
          <br />
          <br />
          </p>
            <TextField id="outlined-basic"
                       label="Book Landing Page URL"
                       variant="outlined"
                       style={{width: '50vw'}}
                       onChange={this.handleTextChange}
                       error={!this.state.inputValid} helperText={this.state.helperText} />
            <Button
                onClick={this.urlSubmitted}
                variant="contained"
                color="primary"
                style={{width: '70px', marginLeft: "10px"}}
                disabled={!this.state.inputValid}>
                Submit
            </Button>
        </form>
    };

    renderDownloadLink = () => {
        if (this.state.downloadLink) {
            console.log(`Download link generated: ${this.state.downloadLink}`)
            return <p>
                <br />
                <br />
                <Button
                href={this.state.downloadLink}
                variant="contained"
                color="primary"
                style={{width: '150px', marginLeft: "10px"}}
                disabled={!this.state.inputValid}>
                Download
                </Button>
            </p>
        }
    };

    render() {
        return <div className='single-container'>
            <PageHeader>EPUB Creator</PageHeader>
            <div style={{height: '100vh'}}>
                <br />
                <br />
                <br />
                {this.renderInput()}
                {this.renderDownloadLink()}
            </div>
        </div>
    }
}