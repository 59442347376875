import React, {Component} from 'react'
import {CardContainer, StyledLink} from '../components/StyledComponents'
import PropTypes from 'prop-types';
import './Landing.scss'
import {
    CARD_ANGULAR_SCHEDULE,
    CARD_BLUE_AND_CREAM,
    CARD_DOCKER,
    CARD_HOLOLENS, CARD_TA, CARD_VOCAB,
    CARD_VOCAB_2
} from "../projects/StyledCard";
import StyledCard from "../projects/StyledCard";
import logo_ubc from '../projects/imgs/ss-ubc.jpg';
import logo_vanquish from '../projects/imgs/ss_VANQUISH.png';
import { CARD_HOOTSUITE, CARD_NEX } from "../projectsv2/ProjectsV2";
import logo_image from "./Copy of IMG_3350.png";

class HoverHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false
        }
    }

    render() {
        if (this.state.isHovering)
            return <span onMouseOver={(e) => this.setState({isHovering: true})}
                         onMouseLeave={(e) => this.setState({isHovering: false})}>{this.props.onHoverElement}</span>;
        else
            return <span onMouseOver={(e) => this.setState({isHovering: true})}
                         onMouseLeave={(e) => this.setState({isHovering: false})}>{this.props.defaultElement}</span>;
    }
}
HoverHeader.propTypes = {
    onHoverElement: PropTypes.element.isRequired,
    defaultElement: PropTypes.element.isRequired
};

class LoadingHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ellipses: ""
        }
    }

    componentDidMount() {
        this.loadingInterval = setInterval(function() {
            this.setState(prevState => {
                return { ellipses: ".".repeat((prevState.ellipses.length + 1) % 6)}
            })
        }.bind(this), 500)
    }

    componentWillUnmount() {
        console.log("Clearing loading interval: ", this.loadingInterval);
        clearInterval(this.loadingInterval)
    }

    render() {
        return (<h3>{this.props.children}{this.state.ellipses}</h3>)
    }
}

const portal = (
    <div className="portal final">
        <div className="bb">
            <h1 style={{
                color: "white",
                backgroundColor: "black",
                lineHeight: "44px",
                margin: "36px auto 0 auto"
            }}>Augustine Kwong</h1>
            <div>
            <LoadingHeader>loading</LoadingHeader>
            </div>
        </div>
    </div>);

const loaded = (
    <div className="banner" id="banner">
        <div className="caption">
            <span className="border">Augustine Kwong</span><br/>
            <span><h2 className="border">
            Thoughtful | Motivated | Collaborative
          </h2></span>
        </div>
    </div>
);

const content = (<div>
        <div className="description">
            <h1><a name="experience">Experience</a></h1>
            <CardContainer>
                {CARD_NEX}
                {CARD_HOOTSUITE}
                {CARD_TA}
            </CardContainer>
        </div>
        <div className="third">
        </div>
        <div className="description final">
            <a name="Education"><h1>Education and Extracurricular</h1></a>
            <CardContainer>
                <StyledCard title="Combined Major CPSC & STAT"
                            staticImgSrc={logo_ubc}
                            subtitle="Bachelor of Science">
                            4.10/4.33 (UBC Standard) 
                            <br/>
                            3.83/4.0 (McGill Standard)
                </StyledCard>
                <StyledCard title="Vice-President of Finance"
                            subtitle="UBC Undergraduate Statistics Society" staticImgSrc={logo_ubc}>
                    September 2018 - December 2018
                </StyledCard>
                <StyledCard title="Top 5 Finish" subtitle="VANquish Collisions Hackathon"
                            staticImgSrc={logo_vanquish}>
                    Collision data visualization model and Android app with a RESTful backend server.
                </StyledCard>
            </CardContainer>
        </div>
    </div>
);

export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {backgroundLoaded: false}
    }

    componentDidMount() {
        let that = this;
        const image = document.createElement('img');
        image.src = logo_image;
        console.log(image.src);
        image.onload = function () {
            that.setState({backgroundLoaded: true});
        }
    }

    render() {
        const landing = this.state.backgroundLoaded ? loaded : portal;
        const loadedContent = this.state.backgroundLoaded ? content: null;
        return (<div className="mainContainer">
            {landing}
            {loadedContent}
        </div>)
    }
}
