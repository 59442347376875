import { PageHeader } from "../styles/global";
import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import { Redirect } from 'react-router-dom';

export default class Highlights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToLogin: false,
            data: []
        };
    }

    componentDidMount() {
        let self = this
        axios.get('/api/highlights')
            .then(function (response) {
                // handle success
                self.setState((prevState) => {
                    return { data: response.data.highlights }
                })
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                if (error.response && error.response.status == 401) {
                    // redirect to login portal
                    self.setState({ redirectToLogin: true })
                }
            })
            .finally(function () {
                // always executed
                console.log("done")
            });
    }

    renderTable = (highlights) => {
        if (highlights) {
            return (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Document</TableCell>
                                <TableCell align="right">Page</TableCell>
                                <TableCell align="right">Comment Author</TableCell>
                                <TableCell align="right">Comment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {highlights.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.fileTitle}
                                    </TableCell>
                                    <TableCell align="right">{row.page}</TableCell>
                                    <TableCell align="right">{row.author}</TableCell>
                                    <TableCell align="right">{row.contents}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    }

    render() {
        if (this.state.redirectToLogin === true) {
            return <Redirect to='/api/auth/login' />
        } else {
            return <div className='single-container'>
                <PageHeader>Highlights</PageHeader>
                {this.renderTable(this.state.data)}
            </div>
        }
    }
}