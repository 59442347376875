import React, { Component } from 'react';
import {
  BrowserRouter as Router, Link, Switch,
  Route, Redirect
} from 'react-router-dom';

import Landing from "./home/Landing";

import './App.css';
import Navbar from "./global/Navbar";
import Contact from "./contact/Contact";
import HomeCourt from "./homecourt/HomeCourt";
import Hootsuite from "./hootsuite/Hootsuite";
import EpubMaker from "./epub-maker/EpubMaker";
import ScrollToTop from "react-router-scroll-top"
import AOS from 'aos';
import 'aos/dist/aos.css';
import APILogin from './APILanding/APILanding'
import Highlights from './highlights/highlights'
AOS.init();

class App extends Component {

  render() {
    return (
      <div className="App">
        <Router>
          <ScrollToTop />
          <div className="mainContainer final" id="app">
            <Navbar />
            <Switch>
              <Route path="/contact" component={Contact} />
              <Route path="/homecourt" component={HomeCourt} />
              <Route path="/hootsuite" component={Hootsuite} />
              <Route path="/landing" component={Landing} />
              <Route path="/highlights" component={Highlights} />
              <Route path="/epub-maker" component={EpubMaker} />
              <Route exact path="/api/auth/login" component={APILogin} />
              <Route path="/" component={Landing} />
            </Switch>
            <footer>
              <Link to="/contact" style={{ textDecoration: "none" }}><h1>Coffee ☕ ?️</h1></Link>
              <h3 style={{ opacity: ".3" }}>© Augustine Kwong 2020</h3>
            </footer>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
