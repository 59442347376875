import React, {Component} from 'react'
import styled from 'styled-components'
import {LeftToRightKeyframes, PageHeader, RightToLeftKeyframes} from "../styles/global";
import homecourt_app from './imgs/profile-frame-v3-resized.png'
import homecourt_global_scout from './imgs/silver_hero_small.png'
import spacer_img1 from "./imgs/hero-unlock.jpg"
import homecourt_watch from './imgs/healthkit_watch.png'
import homecourt_summary from './imgs/healthkit_summary.png'

export default class HomeCourt extends Component {

    render() {
        return (
            <div>
                <PageHeader>HomeCourt (NEX Team)</PageHeader>
                {INTRO_HEADER}
                <HomecourtSpacer>
                    <HomecourtSpacerImage data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-once="true"
                         src={homecourt_summary} alt="HomeCourt Watch Summary"/>
                </HomecourtSpacer>
                {ROLE_DESCRIPTION}
            </div>
        )
    }
}

const HomecourtIntroContainer = styled.div`
  background-color: #fffff7;
  padding: 15px 20px 100px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  
   @media (min-width: 768px) {
    padding: 50px 100px 100px 100px;
    flex-direction: row;
  }
`;

const HomecourtIntroDescription = styled.p`
  font-size: 30px;
  line-height: 32px;
  position: relative;
  
  @media (min-width: 768px) {
    flex-direction: row;
    animation: ${LeftToRightKeyframes} 2s ease-in-out  0s 1;
  -webkit-animation: ${LeftToRightKeyframes} 2s ease-in-out  0s 1;
`;

const HomecourtSpacerImage = styled.img`
  position: relative;
  max-width: 100%;
  
  @media (min-width: 768px) {
    top: -75px; 
  }
`

const HomecourtSpacer = styled.div`
  height: 300px;
  background-image: url("${spacer_img1}"); 
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  overflow: visible;
  z-index: 999;
`;

const HOMECOURT_DESCRIPTION = `HomeCourt uses proven basketball science, developed and refined by the pros, to provide data and stats that help anyone improve and get discovered.`;
const INTRO_HEADER = (<HomecourtIntroContainer>
        <HomecourtIntroDescription data-aos="fade-left"
                                   data-aos-duration="1000">
            {HOMECOURT_DESCRIPTION}
        </HomecourtIntroDescription>
        <a href="https://www.homecourt.ai/"><img data-aos="fade-left"
                                                 data-aos-duration="1000"
                                                 src={homecourt_app} alt="HomeCourt Screenshot" height={500}/></a>
        <a href="https://www.homecourt.ai/"><img data-aos="fade-left"
                                                 data-aos-duration="1000"
                                                 src={homecourt_global_scout} alt="HomeCourt Global Scout" height={500} /></a>
</HomecourtIntroContainer>);

const ROLE_DESCRIPTION = (<HomecourtIntroContainer className="final">
    <a href="https://www.homecourt.ai/"><img data-aos="fade-left"
                                             data-aos-duration="1000"
                                             data-aos-once="true"
                                             src={homecourt_watch} alt="HomeCourt Watch" height={500}/></a>
    <HomecourtIntroDescription data-aos="fade-right"
                               data-aos-duration="1000"
                               data-aos-once="true">
        Developed a <b>brand new</b> watchOS 5 companion app for HomeCourt from <b>conception, prototyping and production release</b>.
        <br/>
        <br/>
        Presented and <b>collaborated</b> with <b>Apple designers</b> to improve the offering.
        <br />
        <br/>
        Built end-to-end features using an <b>analytics-driven</b> approach using <b>Swift and Firebase</b>.
    </HomecourtIntroDescription>
</HomecourtIntroContainer>);
