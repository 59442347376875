import React, {Component} from "react";
import styled from "styled-components";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";

import ss_vocab_2 from './imgs/ss-vocab-new.gif';
import ss_static_vocab_2 from './imgs/ss-vocabulary-list.png';
import ss_angular from './imgs/ss-angular-schedule-compare.gif';
import ss_static_angular from './imgs/ss-static-angular-schedule.png';
import ss_dial_padder from './imgs/ss-dial-pad.gif';
import ss_static_dial_padder from './imgs/ss-dial-padder.png';
import ss_bac from "./imgs/ss-blue-and-cream-parser.png";
import ss_hololens from "./imgs/ss-hololens.png";
import ss_tic_tac from "./imgs/ss-tic-tac-toe.gif";
import ss_static_tic_tac from "./imgs/ss-static-tic-tac-toe.png";
import ss_jupyter_ml from "./imgs/ss-jupyter-ml.png";
import ss_math_344 from "./imgs/ss-math-344.png";
import ss_hootsuite_medium from "./imgs/ss-hootsuite.png";
import ss_ubc from "./imgs/ss-ubc.jpg";
import ss_w2f from "./imgs/ss-w2f.png";
import ss_thinktech from "./imgs/ss-thinktech.gif";
import ss_static_thinktech from "./imgs/ss-static-thinktech.png";
import ss_shopify from "./imgs/ss-shopify-demo.png";
import ss_vocab_old from "./imgs/ss-vocab-old.gif";
import ss_static_vocab_old from "./imgs/ss-static-vocab-old.png";
import ss_prolog from "./imgs/ss-prolog.png";

import {StyledLink} from "../components/StyledComponents";

const VerticallyCenteredText = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
`;

const TitleLink = styled.a`
  font-size: 18px;
  text-decoration: none;
  color: darkseagreen;
`;

const styles = {
  root: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  control: {
    padding: '2px',
  },
  title: {
    width: '100%',
    fontSize: '3em',
    paddingTop: '4px',
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    color: '#777',
    fontSize: '14px',
    padding: '0 20px 0px 20px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row nowrap'
  }
};

export default class StyledCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: props.staticImgSrc,
      isHovering: false
    };

    this.myRef = React.createRef();
  }

  render() {
    const { title, subtitle, link, imgSize, animatedImgSrc, staticImgSrc, imgPosition, children } = this.props

    let media = (isHovering) => {
      return {
      height: '200px',
      backgroundSize: imgSize? imgSize : 'contain',
      backgroundPositionX: imgPosition? imgPosition : 'center',
      transition: 'transform 200ms ease-in',
      transform: isHovering? "scale(1.25) translate(0, 50px)" : 'scale(1) translate(0, 0)'
      }
    };

    let project = (isHovering) => { return {
      width: '300px',
      margin: '12px',
      textAlign: 'center',
      backgroundColor: '#fffff7',
      overflow: 'visible',
      zIndex: isHovering? '99' : '0'
    }};

    return (<Card style={project(this.state.isHovering)}>
      <CardMedia
        ref={this.myRef}
        style={media(this.state.isHovering)}
        image={this.state.imgSrc}
        onMouseEnter={() => { if (animatedImgSrc) this.setState({imgSrc: animatedImgSrc, isHovering: true})}}
        onMouseLeave={() => { this.setState({imgSrc: staticImgSrc, isHovering: false})}}
      />

      <h3><TitleLink href={link}>{title}</TitleLink></h3>
      <h3 style={styles.subtitle}>{subtitle}</h3>

      <CardContent style={styles.content}>
        <VerticallyCenteredText>{children}</VerticallyCenteredText>
      </CardContent>
    </Card>)
  }
}

StyledCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string,
  staticImgSrc: PropTypes.string,
  animatedImgSrc: PropTypes.string,
  height: PropTypes.string,
  imgSize: PropTypes.string,
  imgPosition: PropTypes.string
};

export const CARD_VOCAB = (<StyledCard title="VocabularyList"
                                       subtitle="Java, Android, RESTful APIs"
                                       link="https://github.com/augudyne/VocabularyList"
                                       staticImgSrc={ss_static_vocab_old}
                                       animatedImgSrc={ss_vocab_old}>
  A ListView based activity using Android Fragments and WebsterAPI to asynchronously
  fetch definitions
  and save them. Suggestions are offered for misspelled words. If no internet is detected, word is added
  to an offline-list that user can privately manage.
</StyledCard>);

export const CARD_VOCAB_2 = (<StyledCard
  title="VocabularyList2.0"
  subtitle="Scala, React, PostgreSQL, Android"
  link="https://augustinekwong.com/vocabulary" staticImgSrc={ss_static_vocab_2} animatedImgSrc={ss_vocab_2}>
  React/Scala Play server caching Oxford API calls. Signup and confirmation (Silhouette framework).
  <br/>
  <br/>
  Bearer token authenticated Android client (RESTful endpoints). Users can ADD, PUT and DELETE.
</StyledCard>);


export const CARD_ANGULAR_SCHEDULE = (<StyledCard title="AngularScheduleCompare"
                                                  subtitle="AngularJS, Express.js, CSS, HTML"
                                                  link="https://angular-schedule-compare.herokuapp.com"
                                                  staticImgSrc={ss_static_angular}
                                                  animatedImgSrc={ss_angular}>
  An Angular2 web application that allows users to compare their UBC iCal.ics schedules to find times when they are both free to meet up.
</StyledCard>);

export const CARD_DIAL_PADDER = (<StyledCard title="DialPadder"
                                             subtitle="AngularJS, CSS, HTML"
                                             link="https://angular-schedule-compare.herokuapp.com/dialpadder"
                                             animatedImgSrc={ss_dial_padder} staticImgSrc={ss_static_dial_padder}>
  Input a character string and converts it to the dial-pad representation. Suggests characters to construct a palindrome.
</StyledCard>);

export const CARD_BLUE_AND_CREAM = (<StyledCard title="BlueAndCream Database Parser"
                                                subtitle="Java, JSoup, HTML"
                                                staticImgSrc={ss_bac}
                                                link="https://github.com/augudyne/BlueAndCreamParser">
  A console application leveraging the JSoup library to fetch and parse the entire
  BlueAndCream
  male’s catalogue (> 1,500 products), manipulating and cleaning the data for a database.
  Parses HTML data into Java Objects with name, sizes/colors available, etc. Database can be exported
  and imported via CSV files. Product-codes are detected via Regex.
</StyledCard>);

export const CARD_HOLOLENS = (<StyledCard title="HoloLens Physics Simulation"
                                          subtitle="C#, Unity, Blender"
                                          link="https://github.com/augudyne/HoloLensCannonSimulator"
                                          staticImgSrc={ss_hololens}>
  Project lead on the development of a Microsoft HoloLens Physics simulation game with 2
  team members.
  A spatially mapped Cannon with custom physics allows player to predict projectile trajectory by
  placing markers. Cannon is manually fired, showing the actual path.
</StyledCard>);

export const CARD_TIC_TAC = (<StyledCard title="Tic Tac Toe"
                                         subtitle="C++"
                                         link="https://github.com/augudyne/TicTacToe"
                                         staticImgSrc={ss_static_tic_tac}
                                         animatedImgSrc={ss_tic_tac} >
  Console 2-player Tic-Tac-Toe game. Time limit of an hour to practice coding efficiency.
</StyledCard>);

export const CARD_DOCKER = (<StyledCard title="Docker Jupyter Notebook for ML"
                                        subtitle="Docker, Python, Scripting"
                                        link="https://github.com/augudyne/jupyter-notebook-for-ml"
                                        staticImgSrc={ss_jupyter_ml}>
  Quickly setup a docker container that serves a jupyter notebook compatible with
  Google's Machine.
  <br/>
  <br/>
  Learning Crash Course and provides Hinterland code completion. Helps avoid the frustrations of
  python version/package management via conda/virtualenv.
</StyledCard>);

export const CARD_MATH_344 = (<StyledCard title="Combinatorial Games"
                                          subtitle="Python, Pandas, NumPy"
                                          link="https://github.com/augudyne/math-344-exploration"
                                          staticImgSrc={ss_math_344}>
  Combinatorial games exploration for assignments in MATH 344.
</StyledCard>);

export const CARD_HOOTSUITE = (<StyledCard title="Hootsuite Developer Co-op"
                                           subtitle="January - August 2018"
                                           link="https://medium.com/@augustine.kwong"
                                           staticImgSrc={ss_hootsuite_medium}
                                           height="450px">
    Worked closely with UX designers, managers and mentors to implement preference management with optimistic updates and undo, sign-on flows with deep-link callback, and custom modularized UI components working in Kotlin with Rx, Retrofit2 and Dagger2.
    <br/>
    <br/>
    Proactively asked to work on Scala development, deploying announcement and billing IDs endpoint.
  </StyledCard>
);

export const CARD_TA = (<StyledCard title="Teaching Assistant"
                                    subtitle="September 2016 – December 2017"
                                    staticImgSrc={ss_ubc}
                                    height="450px">
  <StyledLink href="https://courses.students.ubc.ca/cs/courseschedule?pname=subjarea&tname=subj-course&dept=CPSC&course=110">CPSC 110</StyledLink>
  <br/>
  <StyledLink href="https://courses.students.ubc.ca/cs/courseschedule?pname=subjarea&tname=subj-course&dept=CPSC&course=121"> CPSC 121</StyledLink>
  <br/>
  <StyledLink href="https://courses.students.ubc.ca/cs/courseschedule?pname=subjarea&tname=subj-course&dept=CPSC&course=213"> CPSC 213</StyledLink>
  <br/>
  <br/>
  Topics covered (over 3 courses) include functional programming and design patterns, discrete
  mathematics and computer logic, and computer architecture in C and assembly.
</StyledCard>);

export const CARD_W2F = (<StyledCard title="Supervisor"
                                     subtitle="August 2013 – October 2016" staticImgSrc={ss_w2f} height="450px">
  Oversight of 35 – 40 staff. Overhaul of inventory system with Excel saved supervisors over 40 hours.
  Awarded Most Valuable Partner in 2015 and 2016
</StyledCard>);

export const CARD_THINKTECH = (<StyledCard title="ThinkTech"
                                           subtitle="React, Chart.js"
                                           staticImgSrc={ss_static_thinktech}
                                           animatedImgSrc={ss_thinktech}
                                           link="https://kalu-thinktech2018.herokuapp.com/">
  Submission for Deloitte ThinkTech Case Competition
</StyledCard>);

export const CARD_SHOPIFY = (<StyledCard title="Shopify Demo"
                                         subtitle="Scala, Play Framewok"
                                         staticImgSrc={ss_shopify}
                                         link="https://augudyne-shopify.herokuapp.com/">
  Submission for Shopify Back-End Developer Position
</StyledCard>);

export const CARD_PROLOG = (<StyledCard title="Prolog NBA Predictor"
                                        subtitle="Prolog, Terse RDF, Scripting" staticImgSrc={ss_prolog}>
</StyledCard>)
