import React, {Component} from 'react'
import styled from 'styled-components'
import { PageHeader } from "../styles/global";
import hootsuite_logo from './imgs/hootsuite-horizontal-black-registered.png';
import medium_espresso from './imgs/espresso-tests.png'
import hootsuite_demonstration from './imgs/demonstration.gif'
import spacer_img1 from "./imgs/spacer_1.png"
import {LeftToRightKeyframes, RightToLeftKeyframes} from "../styles/global";

export default class Hootsuite extends Component {

    render() {
        return (
            <div>
                <PageHeader>Hootsuite Media, Inc.</PageHeader>
                {INTRO_HEADER}
                <HootsuiteSpacer>
                    <HootsuiteDesktopBanner src={spacer_img1} height={325}/>
                </HootsuiteSpacer>
                {DETAILS_CONTAINER}
                {MORE_DETAILS_CONTAINER}
            </div>
        )
    }
}

const HootsuiteIntroContainer = styled.div`
  background-color: #fffff7;
  position: relative;
  background-attachment: fixed;
  padding: 50px 20px 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    padding: 50px 100px 100px 100px;
    flex-direction: row;
   }
`;

const HootsuiteDescriptionContainer = styled(HootsuiteIntroContainer)`
  padding: 50px 20px;
    @media (min-width: 768px) {
      padding: 50px 100px 100px 100px;
    }
`;

const HootsuiteFinalContainer = styled(HootsuiteIntroContainer)`
    margin-bottom: 150px;
    justify-content: space-evenly;
`;

const HootsuiteIntroDescription = styled.p`
  font-size: 30px;
  line-height: 32px;
  padding: 15px 15px;
  margin: 0;
`;

const HootsuiteSpacer = styled.div`
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: inherit;
  background-color: darkseagreen;
  overflow: hidden;
  z-index: 999;
  border-style: none;
  border-radius: 0;
  
  @media (min-width: 768px) {
    height: 250px;
    overflow: visible;
  }
`;

const HootsuiteDesktopBanner = styled.img`
  position: relative;
  top: -75px;
`;

const LeftToRightImage = styled.img`
  @media (min-width: 768px) {
    animation: ${LeftToRightKeyframes} 1s ease-in-out  0s 1;
    -webkit-animation: ${LeftToRightKeyframes} 1s ease-in-out  0s 1;
  }
`;

const RightToLeftImage = styled.img`
  @media (min-width: 768px) {
    animation: ${RightToLeftKeyframes} 1s ease-in-out  0s 1;
    -webkit-animation: ${RightToLeftKeyframes} 1s ease-in-out  0s 1;
  }
`;

const HOOTSUITE_DESCRIPTION = `Manage multiple social networks, connect with customers, and grow your brand on social media.`;
const INTRO_HEADER = (<HootsuiteIntroContainer>
    <HootsuiteIntroDescription>{HOOTSUITE_DESCRIPTION}</HootsuiteIntroDescription>
    <a href="https://www.hootsuite.com/">
        <RightToLeftImage src={hootsuite_logo} alt="Hootsuite Logo" width="400"/>
    </a>
</HootsuiteIntroContainer>);
const DETAILS_CONTAINER = (<HootsuiteDescriptionContainer>
    <a href="https://medium.com/@augustine.kwong">
        <LeftToRightImage src={medium_espresso} alt="https://medium.com/@augustine.kwong" height={150}/>
    </a>
    <HootsuiteIntroDescription>
        Worked closely with UX designers, managers and mentors to produce user-facing features.
        <br/>
        <br/>
        Android development in Kotlin with Rx, Retrofit2 and Dagger2.
    </HootsuiteIntroDescription>
</HootsuiteDescriptionContainer>);

const MORE_DETAILS_CONTAINER = <HootsuiteFinalContainer>
    <HootsuiteIntroDescription>Check out my blog posts<br/>for <a href="https://medium.com/@augustine.kwong">@hootsuite-engineering</a></HootsuiteIntroDescription>
    <a href="https://medium.com/@augustine.kwong"><img src={hootsuite_demonstration} height={450} alt="Hootsuite Demo"/></a>
</HootsuiteFinalContainer>