import React, { Component } from 'react'
import { StyledLink } from '../components/StyledComponents'
import { PageHeader } from "../styles/global";
import styled from "styled-components";
import { PulseOrange } from "../styles/global";
import logo from "./imgs/logo.png"
import headshot from "./imgs/headshot.png"
import icon_email from "./imgs/icons8-email-100.png"
import icon_linked_in from "./imgs/icons8-linkedin-100.png"

const CelebratoryHeader = styled(PageHeader)`
  animation: ${PulseOrange} 3s ease-in-out 0s 3;
`;

const UnstyledLink = styled.a`
  text-decoration: none;
  color: darkseagreen;
`

export default class Contact extends Component {
  render() {
    return(<div style={{
      width:"100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "flex-start"
    }} className="single-container">
      <div style={{padding: "15px"}}>
        <CelebratoryHeader>You've made a great choice!</CelebratoryHeader>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
          <img src={headshot} alt="Augustine Kwong Headshot" height={300} style={{borderRadius: "25px", boxShadow: "6px 10px darkseagreen"}}/>
        </div>
        <br/>
        <div style={{display: "flex", justifyContent: "center"}}>
          <UnstyledLink href="https://www.linkedin.com/in/augustinekwong/">
            <div style={{width: "100px"}}>
              <h3>LinkedIn</h3>
              <img src={icon_linked_in} height={100} />
            </div>
          </UnstyledLink>
          <UnstyledLink href="mailto:augustinekwong@gmail.com?subject=Ive Got A New Opportunity or Project Idea">
          <div style={{width: "100px"}}>
            <h3>E-mail</h3>
            <img src={icon_email} height={100} />
          </div>
          </UnstyledLink>
        </div>
        <h3>Things I Like</h3>
        <p style={{lineHeight: "18px"}}>
          Coffee<br />
          Green Tea<br />
          Thinking Fast and Slow<br />
          Adventures of Sherlock Holmes<br />
          Yoga, Cycling, Basketball, Hockey
          </p>
      </div>
    </div>)
  }
}
