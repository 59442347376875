import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow_right from "./arrow-right-01-512.png"
import "./ProjectsV2.css"
import { PageHeader } from "../styles/global";

import ss_static_vocab_2 from '../projects/imgs/ss-vocabulary-list.png';
import StyledCard from "../projects/StyledCard";
import MenuItem from "../../node_modules/@material-ui/core/MenuItem/MenuItem";
import Select from "../../node_modules/@material-ui/core/Select/Select";
import FormControl from "../../node_modules/@material-ui/core/FormControl/FormControl";
import ss_bac from "../projects/imgs/ss-blue-and-cream-parser.png";
import ss_vocab_2 from "../projects/imgs/ss-vocab-new.gif";
import ss_static_angular from "../projects/imgs/ss-static-angular-schedule.png";
import ss_angular from "../projects/imgs/ss-angular-schedule-compare.gif";
import ss_dial_padder from "../projects/imgs/ss-dial-pad.gif";
import ss_static_dial_padder from "../projects/imgs/ss-dial-padder.png";
import ss_hololens from "../projects/imgs/ss-hololens.png";
import ss_static_thinktech from "../projects/imgs/ss-static-thinktech.png";
import ss_thinktech from "../projects/imgs/ss-thinktech.gif";
import ss_static_vocab_old from "../projects/imgs/ss-static-vocab-old.png";
import ss_vocab_old from "../projects/imgs/ss-vocab-old.gif";
import ss_static_tic_tac from "../projects/imgs/ss-static-tic-tac-toe.png";
import ss_tic_tac from "../projects/imgs/ss-tic-tac-toe.gif";
import ss_jupyter_ml from "../projects/imgs/ss-jupyter-ml.png";
import ss_math_344 from "../projects/imgs/ss-math-344.png";
import ss_prolog from "../projects/imgs/ss-prolog.png";
import ss_hootsuite_medium from "../projects/imgs/ss-hootsuite.png";
import ss_nex_team from "../projects/imgs/ss-nex-team.png"

const styles = {
    root: {
        position: "relative",
        width: "100%",
    },
    slider: {
        width: "80%",
        margin: "auto",
        paddingBottom: "50px",
    },
    formControl: {
        minWidth: '250px',
        height: '100px',
        borderColor: 'blue',
    },
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            src={arrow_right}
            className={ className }
            style={{ ...style, display: "block", background: "white", opacity: '0.3', width: "25px"}}
            onClick={onClick}>
        </img>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            src={arrow_right}
            className={ className }
            style={{ ...style, display: "block", background: "white", opacity: '0.3', width: "25px", transform: 'rotate(180deg)'}}
            onClick={onClick}>
        </img>
    );
}

function ProjectSlideshow(state) {
    const settings = {
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: state.slidesToShow,
        slidesToScroll: state.slidesToShow,
        arrows: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
    };

    return (
        <Slider {...settings} style={styles.slider}>
            { getSlides(state.filter) }
        </Slider>
    )
}

function getSlides(filter) {
    switch(filter) {
        case "mobile":
            return mobileSlides;
        case "academic":
            return academicSlides;
        default:
            return defaultSlides;
    }
}

export default class ProjectsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "none",
            slidesToShow: 3,
        };

        this.headingRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ slidesToShow: window.innerWidth <= 760 ? 1 : 3 });
    }

    render() {
        const handleChange = event => {
            this.setState({ filter: event.target.value });
        };

        return (
            <div className={'single-container'}>
                <PageHeader ref={this.headingRef}>Projects</PageHeader>
                <FormControl variant="outlined" style={styles.formControl}>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.filter}
                        onChange={handleChange}>
                        <MenuItem value="none">
                            <em>All</em>
                        </MenuItem>
                        <MenuItem value={"mobile"}>Mobile Development</MenuItem>
                        <MenuItem value={"academic"}>Academic</MenuItem>
                    </Select>
                </FormControl>
                <div>
                { ProjectSlideshow(this.state, this.headingRef) }
                </div>
            </div>
        );
    }
}

const CARD_VOCAB_LIST = (<StyledCard
    title="VocabularyList2.0"
    subtitle="Scala, React, PostgreSQL, Android"
    link="https://augustinekwong.com/vocabulary"
    staticImgSrc={ss_static_vocab_2} animatedImgSrc={ss_vocab_2}
    center={true}>
    React/Scala Play server caching Oxford API calls. Signup and confirmation (Silhouette framework).
    <br/>
    <br/>
    Bearer token authenticated Android client (RESTful endpoints). Users can ADD, PUT and DELETE.
</StyledCard>);

const CARD_ANGULAR_COMPARE = (<StyledCard title="AngularScheduleCompare"
                                             subtitle="AngularJS, Express.js, CSS, HTML"
                                             link="https://angular-schedule-compare.herokuapp.com"
                                             center={true}
                                             staticImgSrc={ss_static_angular}
                                             animatedImgSrc={ss_angular}>
        An Angular2 web application that allows users to compare their UBC iCal.ics schedules to find times when they are both free to meet up.
    </StyledCard>);

const CARD_DIAL_PADDER = (<StyledCard title="DialPadder"
                                          subtitle="AngularJS, CSS, HTML"
                                          center={true}
                                          link="https://angular-schedule-compare.herokuapp.com/dialpadder"
                                          animatedImgSrc={ss_dial_padder} staticImgSrc={ss_static_dial_padder}>
        Input a character string and converts it to the dial-pad representation. Suggests characters to construct a palindrome.
    </StyledCard>);



const CARD_THINKTECH = (<StyledCard title="ThinkTech"
                                    subtitle="React, Chart.js"
                                    staticImgSrc={ss_static_thinktech}
                                    animatedImgSrc={ss_thinktech}
                                    center={true}
                                    link="https://kalu-thinktech2018.herokuapp.com/">
    Submission for Deloitte ThinkTech Case Competition
</StyledCard>);

const CARD_VOCAB_LIST_OLD = (<StyledCard title="VocabularyList"
                                         subtitle="Java, Android, RESTful APIs"
                                         link="https://github.com/augudyne/VocabularyList"
                                         staticImgSrc={ss_static_vocab_old}
                                         center={true}
                                         animatedImgSrc={ss_vocab_old}>
    A ListView based activity using Android Fragments and WebsterAPI to asynchronously
    fetch definitions
    and save them. Suggestions are offered for misspelled words. If no internet is detected, word is added
    to an offline-list that user can privately manage.
</StyledCard>);

const CARD_DATABASE_PARSER = (<StyledCard title="BlueAndCream Database Parser"
                                          subtitle="Java, JSoup, HTML"
                                          staticImgSrc={ss_bac}
                                          center={true}
                                          link="https://github.com/augudyne/BlueAndCreamParser">
    A console application leveraging the JSoup library to fetch and parse the entire
    BlueAndCream
    male’s catalogue (> 1,500 products), manipulating and cleaning the data for a database.
    Parses HTML data into Java Objects with name, sizes/colors available, etc. Database can be exported
    and imported via CSV files. Product-codes are detected via Regex.
</StyledCard>);

const CARD_TIC_TAC_TOE = (<StyledCard title="Tic Tac Toe"
                                      subtitle="C++"
                                      link="https://github.com/augudyne/TicTacToe"
                                      center={true}
                                      staticImgSrc={ss_static_tic_tac}
                                      animatedImgSrc={ss_tic_tac} >
    Console 2-player Tic-Tac-Toe game. Time limit of an hour to practice coding efficiency.
</StyledCard>);

const CARD_JUPYTER_DOCKER = (<StyledCard title="Docker Jupyter Notebook for ML"
                                         subtitle="Docker, Python, Scripting"
                                         link="https://github.com/augudyne/jupyter-notebook-for-ml"
                                         center={true}
                                         staticImgSrc={ss_jupyter_ml}>
    Quickly setup a docker container that serves a jupyter notebook compatible with
    Google's Machine.
    <br/>
    <br/>
    Learning Crash Course and provides Hinterland code completion. Helps avoid the frustrations of
    python version/package management via conda/virtualenv.
</StyledCard>);

const CARD_MATH_344 = (<StyledCard title="Combinatorial Games"
                                   subtitle="Python, Pandas, NumPy"
                                   link="https://github.com/augudyne/math-344-exploration"
                                   center={true}
                                   staticImgSrc={ss_math_344}>
    Combinatorial games exploration for assignments in MATH 344.
</StyledCard>);

const CARD_PROLOG = (<StyledCard title="Prolog NBA Predictor"
                                 subtitle="Prolog, Terse RDF, Scripting" staticImgSrc={ss_prolog} center={true}>
</StyledCard>);

const CARD_HOLOLENS = (<StyledCard title="HoloLens Physics Simulation"
                                   subtitle="C#, Unity, Blender"
                                   link="https://github.com/augudyne/HoloLensCannonSimulator"
                                   center={true}
                                   staticImgSrc={ss_hololens}>
    Project lead on the development of a Microsoft HoloLens Physics simulation game with 2
    team members.
    A spatially mapped Cannon with custom physics allows player to predict projectile trajectory by
    placing markers. Cannon is manually fired, showing the actual path.
</StyledCard>);

export const CARD_NEX = (<StyledCard title="NEX Team - Software Engineeer"
                                     subtitle="May 2019 - Current"
                                     center={true}
                                     staticImgSrc={ss_nex_team}
                                     link="/homecourt"
                                     height="450px">
    Project lead and maker of <a href="https://www.nex.inc/partyfowl">Party Fowl</a>   
    <br/>
    <br/>                                  
    Delivered a new watchOS companion app from scratch to production,
    presenting and working with Apple designers to improve the offering.
    <br/>
    <br/>
    Built end-to-end features using an analytics-driven approach using Swift and Firebase.
</StyledCard>);

export const CARD_HOOTSUITE = (<StyledCard title="Hootsuite Developer Co-op"
                                    subtitle="January - August 2018"
                                    link="/hootsuite"
                                    staticImgSrc={ss_hootsuite_medium}
                                    center={true}
                                    height="450px">
        Android development in Kotlin with Rx, Retrofit2 and Dagger2.
        Worked closely with UX designers and mentors to build,
        iterate and test new user flows and features.
        <br/>
        <br/>
        Proactively asked to work on Scala development, deploying announcement and billing IDs endpoint.
    </StyledCard>);


const defaultSlides = [
    CARD_VOCAB_LIST,
    CARD_ANGULAR_COMPARE,
    CARD_DIAL_PADDER,
    CARD_THINKTECH,
    CARD_VOCAB_LIST_OLD,
    CARD_DATABASE_PARSER,
    CARD_TIC_TAC_TOE,
    CARD_JUPYTER_DOCKER,
    CARD_MATH_344,
    CARD_PROLOG,
    CARD_HOLOLENS,
];

const mobileSlides = [
    CARD_NEX,
    CARD_VOCAB_LIST,
    CARD_HOOTSUITE,
];

const academicSlides = [CARD_MATH_344, CARD_PROLOG, CARD_THINKTECH, CARD_HOLOLENS, CARD_TIC_TAC_TOE,];